<template>
  <div class="admin container-fluid row g-0 min-vh-100 bg-primary" v-if="isAuthenticated">
    <div class="col-lg-2">
      <nav
        class="
          navbar navbar-dark navbar-expand-lg
          bg-primary
          d-flex
          px-3 px-lg-0
          flex-lg-column
          align-items-lg-start
        "
      >
        <router-link to="/" class="navbar-brand fs-6 fw-light p-3">首頁</router-link>
        <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#nav-collapse">
          <i class="navbar-toggler-icon"></i>
        </button>
        <div class="collapse navbar-collapse" id="nav-collapse">
          <ul class="navbar-nav d-flex flex-column my-lg-4">
            <li v-for="item in navList" :key="item.name" class="nav-item px-3">
              <router-link class="nav-link py-3 px-0" :to="item.path">{{ item.name }}</router-link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="col-lg-10 bg-light min-vh-100 shadow-md">
      <nav class="p-3 bg-white d-flex justify-content-between align-items-center">
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item">
            後台管理
            <i class="fas fa-angle-right"></i>
          </li>
          <li class="breadcrumb-item active">{{ this.$route.meta.title }}</li>
        </ol>
        <span class="ms-auto me-3" :class="isAuthenticated ? 'text-success' : 'text-danger'">
          {{ isAuthenticated ? "✨ 登入中" : "未登入" }}
        </span>
        <button type="button" class="btn btn-sm btn-outline-secondary" @click="logout">登出</button>
      </nav>
      <router-view class="p-5"></router-view>
    </div>
  </div>
</template>

<script>
import JWT from "@/common/cookies";
import { apiRefreshToken } from "@/api";

export default {
  data() {
    return {
      isAuthenticated: false,
      navList: [
        { path: "/admin", name: "最新消息" },
        { path: "/admin/solutions", name: "解決方案" },
        { path: "/admin/banner", name: "輪播圖片" },
        { path: "/admin/brand", name: "廠商及經銷產品" },
        { path: "/admin/product-list", name: "產品類型" },
        { path: "/admin/menu", name: "選單異動" },
      ],
    };
  },
  methods: {
    logout() {
      JWT.removeToken();
      JWT.removeRefreshToken();
      this.$router.push("/");
    },
    async checkLogin() {
      this.$vLoading(true);
      const TOKEN = JWT.getToken();
      if (!TOKEN || TOKEN === "undefined") {
        this.$router.push("/login");
      } else {
        this.$vLoading(false);
        this.isAuthenticated = true;
        return;
      }
      try {
        const REFRESH_TOKEN = JWT.getRefreshToken();
        if (!TOKEN && REFRESH_TOKEN) {
          const res = await apiRefreshToken(`refreshToken=${REFRESH_TOKEN}`);
          const { token, expireTime, refreshToken } = res.data;
          JWT.saveToken(token, expireTime);
          const endOfToday = `${this.$utilsFilters.dateStr()} 23:59:59`;
          JWT.saveRefreshToken(refreshToken, endOfToday);
          this.isAuthenticated = true;
        } else {
          this.$router.push("/login");
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
  },
  created() {
    this.checkLogin();
  },
};
</script>

<style lang="scss">
.admin .nav-link.router-link-exact-active {
  color: white !important;
}
</style>
